/* latin-ext */
@font-face {
  font-family: 'Spartan';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/spartan/v14/l7gfbjR61M69yt8Z2QytlAXJExtwBw.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Spartan';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/spartan/v14/l7gfbjR61M69yt8Z2QKtlAXJExs.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Spartan';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/spartan/v14/l7gfbjR61M69yt8Z2QytlAXJExtwBw.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Spartan';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/spartan/v14/l7gfbjR61M69yt8Z2QKtlAXJExs.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Spartan';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/spartan/v14/l7gfbjR61M69yt8Z2QytlAXJExtwBw.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Spartan';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/spartan/v14/l7gfbjR61M69yt8Z2QKtlAXJExs.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Spartan';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/spartan/v14/l7gfbjR61M69yt8Z2QytlAXJExtwBw.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Spartan';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/spartan/v14/l7gfbjR61M69yt8Z2QKtlAXJExs.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Spartan';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/spartan/v14/l7gfbjR61M69yt8Z2QytlAXJExtwBw.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Spartan';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/spartan/v14/l7gfbjR61M69yt8Z2QKtlAXJExs.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Spartan';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/spartan/v14/l7gfbjR61M69yt8Z2QytlAXJExtwBw.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Spartan';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/spartan/v14/l7gfbjR61M69yt8Z2QKtlAXJExs.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

body {
  font-family: Spartan, sans-serif;
  margin: 0px;
  background-color: #f5f6fa; /* grey100 */
}

.display-lg {
  font-size: 57px;
  line-height: 64px;
  font-weight: 700;
}

.display-md {
  font-size: 45px;
  line-height: 52px;
  font-weight: 700;
}

.display-sm {
  font-size: 36px;
  line-height: 44px;
  font-weight: 700;
}

.display-xs-medium {
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
}

.headline-lg {
  font-size: 32px;
  line-height: 40px;
  font-weight: 600;
}

.headline-md {
  font-size: 28px;
  line-height: 36px;
  font-weight: 600;
}

.headline-sm {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
}

.title-lg {
  font-size: 22px;
  line-height: 28px;
  font-weight: 700;
}

.title-md {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  letter-spacing: 0.15px;
}

.title-sm {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 0.1px;
}

.label-xl {
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  letter-spacing: 0.1px;
}

.label-lg {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 0.1px;
}

.label-md {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.label-sm {
  font-size: 11px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.body-lg {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.body-md {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  letter-spacing: 0.25px;
}

.body-sm {
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  letter-spacing: 0.4px;
}
