.Toastify__toast-container {
  position: absolute;
  top: 0px;
  height: 100%;
  width: 100%;
  max-height: 100%;
  padding: 0px;
}

.Toastify__toast {
  height: 100%;
  max-height: 100%;
  border-radius: 0px;
  padding: 0px;
  font-family: Spartan, sans-serif;
}

.Toastify__toast-body {
  height: 100%;
  max-height: 100%;
  padding: 0px;
}

.Toastify__toast-body > div:last-child {
  height: 100%;
  max-height: 100%;
}
